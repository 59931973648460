<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t("roles") }}
      </v-card-title>
      <v-card-text>
        <v-col cols="12" class="text-end">
          <v-btn
            @click="showEditDialog(undefined)"
            data-testid="rolesListNewRole"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t("newRole") }}
          </v-btn>
        </v-col>
        <v-row>
          <v-col cols="12">
            <p>
              {{ $t("rolesInfo") }}
            </p>
          </v-col>
        </v-row>
        <v-text-field
          :label="$t('search')"
          v-model="search"
          data-testid="rolesListSearch"
        />
        <DataTable
          :headers="headers"
          :items="roles"
          :search="search"
          :custom-filter="filterList"
          data-testid="rolesList"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon @click="showEditDialog(item)" data-testid="rolesListEdit">
              mdi-pencil
            </v-icon>
            <v-icon
              @click="confirmDelete(item)"
              class="ml-4"
              data-testid="rolesListDelete"
            >
              mdi-delete
            </v-icon>
          </template>
        </DataTable>
      </v-card-text>
      <v-card-actions v-if="closeBtn">
        <v-spacer />
        <v-btn @click="emitClose" class="primary" data-testid="closeRoleList">{{
          $t("close")
        }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogDelete" :max-width="dialogMaxWidth">
      <UserConfirm
        :text="$t('deleteRole?')"
        v-on:cancel="dialogDelete = false"
        v-on:ok="
          deleteRole();
          dialogDelete = false;
        "
      />
    </v-dialog>

    <v-dialog
      v-model="dialogEdit"
      :max-width="dialogMaxWidth"
      v-if="dialogEdit"
    >
      <RoleDetails
        :role="selectedRole"
        v-on:done="update"
        v-on:cancel="dialogEdit = false"
      />
    </v-dialog>

    <v-dialog v-model="dialogInUseInfo" :max-width="dialogMaxWidth">
      <UserWarning
        :text="$t('roleCannotBeDeleted')"
        v-on:ok="dialogInUseInfo = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import UserConfirm from "@/components/misc/UserConfirm";
import config from "@/config/config";
import apiRole from "@/api/v24/api.role";
import RoleDetails from "@/components/roles/RoleDetails";
import UserWarning from "@/components/misc/UserWarning";
import store from "@/store";
import search from "@/util/search";
import DataTable from "@/components/dataTable/DataTable.vue";

export default {
  name: "RoleList",
  components: { DataTable, UserWarning, RoleDetails, UserConfirm },

  props: {
    closeBtn: {
      type: Boolean
    }
  },

  computed: {
    headers() {
      return [
        { text: this.$t("name"), value: "Navn" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ];
    },
    roles() {
      return store.state.roles;
    }
  },

  data: () => ({
    dialogDelete: false,
    dialogEdit: false,
    dialogInUseInfo: false,
    dialogMaxWidth: config.dialogWith.medium,
    selectedRole: null,
    search: ""
  }),

  created() {
    this.update();
  },

  methods: {
    filterList(value, query) {
      return search.whitespaceAsWildcard(value, query);
    },
    update() {
      this.dialogEdit = false;
      apiRole.getAll().then(roles => store.commit("setRoles", roles));
    },

    // Set id to null to create new
    showEditDialog(role) {
      this.selectedRole = role;
      this.dialogEdit = true;
    },

    confirmDelete(role) {
      this.selectedRole = role;
      this.dialogDelete = true;
    },

    deleteRole() {
      apiRole
        .delete(this.selectedRole.RolleId)
        .then(() => {
          this.update();
        })
        .catch(err => {
          if (err._inUse) {
            this.dialogInUseInfo = true;
          }
        });
    },
    emitClose() {
      this.$emit("close");
    }
  }
};
</script>
