<template>
  <v-container>
    <SystemUserList />
  </v-container>
</template>

<script>
import SystemUserList from "@/components/userManagement/SystemUserList";
export default {
  name: "UserManagement",
  components: { SystemUserList }
};
</script>
