<template>
  <SingleMailComposer
    :card-title="$t('resetPassword')"
    :card-text="$t('resetPasswordMailInfo')"
    :init-mail-subject="$t('groupAlert') + ' - ' + $t('resetPassword')"
    :to-email="toEmail"
    :to-name="toName"
    :sender-name="senderName"
    :init-email-text="mailBody"
    v-on:done="emitDone"
  />
</template>

<script>
import config from "@/config/config";
import SingleMailComposer from "@/components/mail/SingleMailComposer";

export default {
  name: "ResetPassMailComponser",
  components: { SingleMailComposer },
  props: {
    toEmail: {
      type: String,
      required: true
    },
    toName: {
      type: String,
      required: true
    },
    senderName: {
      type: String,
      required: true
    }
  },

  data: () => ({
    mailSubject: "",
    mailBody: ""
  }),

  created() {
    this.mailSubject = this.$t("welcomeToGA");
    const resetUrl =
      config.onboardingUrl + "?email=" + encodeURIComponent(this.toEmail);
    this.mailBody = this.$t("resetPasswordMailContent", {
      name: this.senderName,
      url: resetUrl
    });
  },

  methods: {
    emitDone() {
      this.$emit("done");
    }
  }
};
</script>
