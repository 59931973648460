<template>
  <v-form autocomplete="off" v-model="formValid" v-if="user" ref="userForm">
    <v-card>
      <v-card-title>
        {{ title }}
        <v-spacer />
        <v-chip v-if="user.Administrator" class="ma-2" color="blue" label dark>
          <v-icon left>
            mdi-crown
          </v-icon>
          {{ $t("administrator") }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-text-field
          data-testid="systemUsersDetailsName"
          class="mt-4"
          v-model="user.Navn"
          maxlength="50"
          outlined
          :label="$t('name')"
          :rules="requiredRules"
          autofocus
        />
        <PhoneNumberInput
          data-testid="systemUsersDetailsPhone"
          :hide-phone-type="true"
          :hide-phone-number-type="true"
          :country-code="phone.countryCode"
          :number="phone.number"
          :label="$t('mobile')"
          :phone-type="phone.type"
          v-on:change="setPhone"
          class="mb-2"
        />
        <div class="mb-6" v-html="$t('userStictMob')"></div>
        <v-text-field
          data-testid="systemUsersDetailsEmail"
          class="mt-6"
          :label="$t('email')"
          type="email"
          v-model="user.Epost"
          outlined
          append-icon="mdi-email"
          maxlength="200"
          :rules="requiredEmailRules"
        />
        <v-checkbox
          data-testid="systemUsersDetailsIsAdminCheckbox"
          v-if="userCanDeactivateAdminRole && loggedInUserIsAdmin"
          v-model="user.Administrator"
          :label="$t('administrator')"
          :hint="$t('adminRights')"
          :disabled="departmentSelecHasFocus"
          persistent-hint
          class="mb-6 mt-n3"
        />
        <v-row>
          <v-col cols="12" v-if="!usePermissionRoles">
            <v-checkbox
              data-testid="systemUsersDetailsCanEditTemplatesCheckbox"
              v-show="!user.Administrator"
              v-model="user.Tilgang.Maler"
              :label="$t('canEditTemp')"
            />
            <v-checkbox
              data-testid="systemUsersDetailsCanEditAlertsCheckbox"
              v-show="!user.Administrator"
              v-model="user.Tilgang.VarslingGruppe"
              :label="$t('canSendAlerts')"
            />
            <v-checkbox
              data-testid="systemUsersDetailsCanEditGroupsCheckbox"
              v-show="!user.Administrator"
              v-model="user.Tilgang.Grupper"
              :label="$t('canEditGroups')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <DepartmentsMultiSelect
              v-if="!user.Administrator"
              :init-value="user.Avdelinger"
              :limit-to-logged-in-users-departments="loggedInUserIsLocalAdmin"
              v-on:change="setDepartments"
              v-on:focus="departmentSelecHasFocus = true"
              v-on:blur="departmentSelecHasFocus = false"
              class="mb-6"
              data-testid="systemUsersDetailsDepartmentMultiSelect"
            />
            <RoleSelect
              v-if="!user.Administrator && usePermissionRoles"
              :init-value="user.Roller"
              v-on:change="setRoles"
            />

            <v-row v-if="!user.Administrator && usePermissionRoles">
              <v-col cols="12">
                <v-btn
                  @click="dialogRoles = true"
                  outlined
                  class="float-right mt-n6"
                  data-testid="manageRolesSystemUserDetails"
                >
                  {{ $t("manageRoles") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  data-testid="systemUsersDetailsID"
                  :label="$t('ID') + $t('optionalHint')"
                  v-model="user.AnsattId"
                  outlined
                  maxlength="32"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col cols="12" md="8" xs="12">
              <v-btn
                data-testid="systemUsersDetailsDeactivate"
                @click="deactivate"
                class="error mt-2"
                small
                v-if="userCanBeDeactivated"
                :block="$vuetify.breakpoint.xs"
              >
                {{ $t("deactivateUser") }}
              </v-btn>
              <span v-if="!$vuetify.breakpoint.xs" class="ml-2" />
              <v-btn
                class="mt-2"
                v-if="userCanBeDeactivated"
                @click="dialogResetPass = true"
                :block="$vuetify.breakpoint.xs"
                small
              >
                {{ $t("resetPassword") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" xs="12">
              <v-btn
                @click="cancel"
                data-testid="systemUsersDetailsCancel"
                :block="$vuetify.breakpoint.xs"
                class="mt-2"
              >
                {{ $t("cancel") }}
              </v-btn>
              <span v-if="!$vuetify.breakpoint.xs" class="ml-2" />
              <v-btn
                class="primary mt-2"
                @click="checkDepartments"
                :disabled="!isFormValid"
                data-testid="systemUsersDetailsSave"
                :block="$vuetify.breakpoint.xs"
              >
                {{ $t("save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogPhoneTypeFail" max-width="600">
      <UserError v-on:ok="dialogPhoneTypeFail = false">
        <template v-slot:title>
          {{ $t("cannotSave") }}
        </template>
        <template v-slot:text>
          <span v-html="$t('userStictMob')"></span>
        </template>
      </UserError>
    </v-dialog>
    <v-dialog
      v-model="dialogSendInfo"
      max-width="700"
      v-if="dialogSendInfo"
      persistent
    >
      <UserWelcomeMailComposer
        :sender-name="adminUserName"
        :to-name="user.Navn"
        :to-email="user.Epost"
        v-on:done="userInfoSendingDone"
      />
    </v-dialog>

    <v-dialog v-model="dialogDupeCustId" max-width="700">
      <UserWarning
        :text="$t('userCustomIdInUse')"
        v-on:ok="dialogDupeCustId = false"
      />
    </v-dialog>

    <v-dialog v-model="dialogDupeEmail" max-width="700">
      <UserWarning
        :text="$t('userEmailInUse')"
        v-on:ok="dialogDupeEmail = false"
      />
    </v-dialog>

    <v-dialog v-model="dialogRoles" max-width="700" persistent>
      <RoleList close-btn v-on:close="dialogRoles = false" />
    </v-dialog>

    <v-dialog v-model="dialogMissingMainDep" max-width="700">
      <UserWarning
        :text="$t('mainDepartmentMissing')"
        v-on:ok="dialogMissingMainDep = false"
      />
    </v-dialog>

    <v-dialog v-model="dialogResetPass" max-width="700">
      <ResetPassMailComposer
        :to-email="user.Epost"
        :to-name="user.Navn"
        :sender-name="adminUserName"
        v-on:done="dialogResetPass = false"
      />
    </v-dialog>

    <v-dialog v-model="dialogNoDep" max-width="700">
      <UserConfirm
        :text="$t('userNoDepsWarning')"
        v-on:cancel="dialogNoDep = false"
        v-on:ok="save"
      />
    </v-dialog>

    <v-dialog v-model="dialogMissingDep" max-width="700">
      <UserWarning
        :text="$t('mustSelectDepartment')"
        v-on:ok="dialogMissingDep = false"
      />
    </v-dialog>
  </v-form>
</template>

<script>
import UserError from "@/components/misc/UserError";
import PhoneNumberInput from "@/components/misc/PhoneNumberInput";
import formValidationRules from "@/util/formValidationRules";
import store from "@/store";
import apiAdmin from "@/api/v24/api.admin";
import PhoneNumber from "@/util/PhoneNumber";
import appSnackbar from "@/util/appSnackbac";
import UserWelcomeMailComposer from "@/components/userManagement/UserWelcomeMailComposer";
import ResetPassMailComposer from "@/components/userManagement/ResetPassMailComposer";
import RoleSelect from "@/components/roles/RoleSelect";
import DepartmentsMultiSelect from "@/components/departments/DepartmentsMultiSelect";
import UserWarning from "@/components/misc/UserWarning";
import i18n from "@/i18n";
import RoleList from "@/components/roles/RoleList";
import apiDepartment from "@/api/v24/api.department";
import UserConfirm from "@/components/misc/UserConfirm.vue";
import ReceiverPicker from "@/components/receivers/ReceiverPicker.vue";
import ReceiversList from "@/components/receivers/ReceiversList.vue";
import Composer from "@/components/alert/Composer.vue";
import department from "@/util/department";

export default {
  name: "SystemUserDetails",

  components: {
    Composer,
    ReceiversList,
    ReceiverPicker,
    UserConfirm,
    RoleList,
    UserWarning,
    DepartmentsMultiSelect,
    RoleSelect,
    UserWelcomeMailComposer,
    ResetPassMailComposer,
    UserError,
    PhoneNumberInput
  },

  props: {
    userId: {
      type: String
    }
  },

  watch: {
    userId: {
      immediate: true,
      handler() {
        this.fetchUser();
      }
    }
  },

  computed: {
    title() {
      return this.userIsNew() ? this.$t("newUser") : this.user.Navn;
    },
    // Yes, it is in fact possible to se this view without being an admin, as
    // long as logged in user as the system permission to edit users. But
    // there are one feature (maybe more later) that is restricted to real admins.
    loggedInUserIsAdmin() {
      return store.state.userProfile?.Bruker.Admin;
    },
    loggedInUserIsLocalAdmin() {
      return (
        !store.state.userProfile?.Bruker.Admin &&
        store.state.userProfile?.Bruker._permissions.manageUsers
      );
    },
    customerHasDepartments() {
      return store.getters.getCustomerHasDepartments;
    },
    adminUserName() {
      return store.getters.getUserFullName;
    },
    usePermissionRoles() {
      return store.state.userProfile?.Rollestyring || false;
    },
    isFormValid() {
      return this.formValid;
    }
  },

  data: () => ({
    user: null,
    dialogPhoneTypeFail: false,
    dialogSendInfo: false,
    dialogDupeCustId: false,
    dialogDupeEmail: false,
    dialogRoles: false,
    dialogMissingMainDep: false,
    dialogMissingDep: false,
    dialogResetPass: false,
    dialogNoDep: false,
    formValid: false,
    requiredEmailRules: formValidationRules.requiredEmailRules,
    requiredRules: formValidationRules.requiredRule,
    changedDepartments: null,
    roles: [],
    phone: {
      countryCode: store.getters.getDefaultCountryPhoneCode,
      number: "",
      type: ""
    },
    userCanBeDeactivated: false,
    userCanDeactivateAdminRole: false,
    // Hack to avoid unwanted click on admin input when pressing outside
    // department selector to close it
    departmentSelecHasFocus: false
  }),

  methods: {
    fetchUser() {
      if (!this.userId) {
        this.user = getUserObject();
        this.userCanDeactivateAdminRole = true;
        this.userCanBeDeactivated = false;
        return;
      }
      apiAdmin.getUser(this.userId).then(user => {
        this.user = user;
        if (this.user.Mobilnr) {
          const phone = new PhoneNumber(this.user.Mobilnr);
          this.setPhone(phone.getAsApiFormat());
        } else {
          this.setPhone(null);
        }

        const roleIds = this.user.Roller.map(role => role.RolleId);
        this.setRoles(roleIds);
        const _user = this.user;

        store.dispatch("updateUserProfile").then(up => {
          if (up.Bruker.BrukerId !== _user.BrukerId) {
            this.userCanDeactivateAdminRole = true;
            this.userCanBeDeactivated = true;
          }
        });
      });
    },
    setRoles(roles) {
      this.roles = roles;
    },
    setPhone(phone) {
      if (phone) {
        this.user.Mobilnr = phone.countryCode + phone.number;
        this.phone.countryCode = phone.countryCode;
        this.phone.number = phone.number;
        this.phone.type = phone.type;
      } else {
        this.user.Mobilnr = "";
        this.phone.countryCode = store.getters.getDefaultCountryPhoneCode;
        this.phone.number = "";
        this.phone.type = "";
      }
    },
    setDepartments(depData) {
      this.changedDepartments = depData.selectedDepartments;
      this.user.HovedAvdeling = depData.mainDepartment;
    },
    userIsNew() {
      return !this.user.BrukerId;
    },
    deactivate() {
      apiAdmin.deactivateUser(this.user).then(() => {
        this.emitSave();
        appSnackbar.setMessage(this.$t("userDeactivated"));
      });
    },
    cancel() {
      this.$emit("cancel");
    },
    checkDepartments() {
      const depsAreAssigned = this.departmentsAreAssigned();
      if (depsAreAssigned) {
        this.save();
        return;
      }
      // Departments are not assinged. If this is a customer using departments
      // and the user is not an admin - this new/changed user will then have
      // read access to all departments. In that case we warn the user with a
      // confirmation dialog.
      store.dispatch("updateUserProfile").then(usrProfile => {
        if (
          !this.user.Administrator &&
          store.getters.getCustomerHasDepartments
        ) {
          this.dialogNoDep = true;
        } else {
          this.save();
        }
      });
    },
    departmentsAreAssigned() {
      if (this.changedDepartments === null) {
        // No changes to departments. Check if this user has existing
        // departments
        return !!this.user.Avdelinger?.length;
      }
      // Departments has been changes on this user. Check if there are any
      return !!this.changedDepartments?.length;
    },
    save() {
      if (this.changedDepartments?.length && !this.user.HovedAvdeling) {
        // Sould never come here, should be handled by department select
        // component. Just an extra safty
        this.dialogMissingMainDep = true;
        return;
      }

      const onSaveDone = userId => {
        const promises = [];
        if (this.usePermissionRoles) {
          let roles = this.roles;
          const promiseRoles = apiAdmin.setUserRoles(userId, roles);
          promises.push(promiseRoles);
        }

        if (this.changedDepartments) {
          const promiseDep = apiAdmin.setUserDepartments(
            userId,
            this.changedDepartments
          );
          promises.push(promiseDep);
        }

        Promise.all(promises).then(() => {
          if (this.userIsNew()) {
            this.dialogSendInfo = true;
          } else {
            appSnackbar.setMessage(this.$t("userSaved"));
            this.emitSave();
          }
        });
      };
      const handleError = error => {
        const details = error.response?.data.Errors[0].Details;
        if (error._dupe && details === "AnsattId") {
          this.dialogDupeCustId = true;
        } else if (
          error._dupe &&
          (details === "Epost" || details === "Email")
        ) {
          this.dialogDupeEmail = true;
        } else {
          console.log(error);
          alert(this.$t("unknownError"));
        }
      };

      if (!this.phone.number || this.phone.type !== "M") {
        this.dialogPhoneTypeFail = true;
        return;
      }

      this.validateUsersDepartments().then(fail => {
        if (fail) {
          this.dialogMissingDep = true;
        } else {
          if (this.user.Administrator) {
            // Reset properties that are not relevant for an admin, so they will
            // not show up again, potentially outdated, if the user is degraded
            // from admin
            this.user.HovedAvdeling = "";
            this.changedDepartments = [];
            this.roles = [];
          }

          if (this.userIsNew()) {
            apiAdmin
              .createUser(this.user)
              .then(newUser => {
                onSaveDone(newUser.BrukerId);
              })
              .catch(err => {
                handleError(err);
              });
          } else {
            apiAdmin
              .changeUser(this.user)
              .then(() => {
                onSaveDone(this.user.BrukerId);
              })
              .catch(err => {
                handleError(err);
              });
          }
        }
      });
    },

    validateUsersDepartments() {
      return new Promise((resolve, reject) => {
        if (this.user.Administrator || store.state.userProfile?.Bruker.Admin) {
          resolve(false);
          return;
        }
        department.customerUsesDepartments().then(customerUsesDeps => {
          // If the user is not an admin, and the customer uses departments,
          // and the user is not assigned any departments, then we need to
          // reject this
          if (!customerUsesDeps) {
            resolve(false);
            return;
          }
          if (
            this.changedDepartments !== null &&
            this.changedDepartments.length === 0
          ) {
            resolve(true);
            return;
          }
          if (
            this.changedDepartments === null &&
            this.user.Avdelinger.length === 0
          ) {
            resolve(true);
            return;
          }
          resolve(false);
        });
      });
    },
    userInfoSendingDone() {
      this.dialogSendInfo = false;
      this.emitSave();
    },
    emitSave() {
      this.$emit("save");
    }
  }
};

const getUserObject = () => {
  return {
    BrukerId: "",
    FagEtat: "",
    KID: "",
    Sperret: false,
    HovedAvdeling: "",
    Mobilnr: "",
    Administrator: false,
    AcceptGDPR: false,
    Epost: "",
    Simulering: false,
    Navn: "",
    Roller: [],
    Avdelinger: [],
    Tilgang: {
      VarslingSmsPremium: false,
      VarslingLokasjon: false,
      Maler: false,
      VarslingTime: false,
      VarslingSaab: false,
      VarslingGruppe: false,
      VA: false,
      Hendelse: false,
      Grupper: false,
      VarslingBefolkning: false,
      VarslingKommune: false,
      Saab: false
    }
  };
};
</script>
