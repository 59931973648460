<template>
  <v-form @submit.prevent="save" autocomplete="off" v-model="formValid">
    <v-card>
      <v-card-title>
        {{ roleData.RolleId ? $t("editRole") : $t("newRole") }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="roleData.Navn"
          :label="$t('name')"
          outlined
          autofocus
          maxlength="50"
          :rules="requiredFieldRule"
          data-testid="rolesDetailsName"
        />
        <v-card>
          <v-card-title>
            {{ $t("permissions") }}
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-model="roleData.Tilgang.Mal"
              :label="$t('permissionTemplates')"
              data-testid="rolesDetailsCheckboxTemplate"
            />
            <v-checkbox
              v-model="roleData.Tilgang.Kontakt"
              :label="$t('permissionContact')"
              data-testid="rolesDetailsCheckboxContacts"
            />
            <v-checkbox
              v-model="roleData.Tilgang.Bruker"
              :label="$t('permissionUser')"
              data-testid="rolesDetailsCheckboxUsers"
            />
            <v-checkbox
              v-model="roleData.Tilgang.Send"
              :label="$t('permissionAlert')"
              data-testid="rolesDetailsCheckboxAlert"
            />
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="emitCancel" data-testid="rolesDetailsCancel">{{ $t("cancel") }}</v-btn>
          <v-btn 
            type="submit" 
            class="primary"
            :disabled="!formValid" 
            data-testid="rolesDetailsSave">{{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import apiRole from "@/api/v24/api.role";
import objectUtil from "@/util/objectUtil";
import formValidationRules from "../../util/formValidationRules";

export default {
  name: "RoleDetails",

  props: {
    role: {
      default: () => {
        return getEmptyRole();
      }
    }
  },

  watch: {
    role: {
      immediate: true,
      handler() {
        this.roleData = objectUtil.deepClone(this.role);
      }
    }
  },

  data: () => ({
    roleData: null,
    nameEdited: "",
    formValid: true,
    requiredFieldRule: formValidationRules.requiredRule,
  }),

  methods: {
    save() {
      let promise;
      if (this.roleData.RolleId) {
        promise = apiRole.edit(this.roleData);
      } else {
        promise = apiRole.create(this.roleData);
      }
      promise.finally(() => {
        this.emitDone();
      });
    },
    reset() {
      this.roleData = getEmptyRole();
    },
    emitCancel() {
      this.reset();
      this.$emit("cancel");
    },
    emitDone() {
      this.reset();
      this.$emit("done");
    }
  }
};

const getEmptyRole = () => {
  return {
    Navn: "",
    RolleId: null,
    Tilgang: {
      Mal: false,
      Konto: false,
      Kontakt: false,
      Send: false,
      Bruker: false
    }
  };
};
</script>

<style scoped></style>
