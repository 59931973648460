import api from "./api";

const apiRole = {
  getAll() {
    return api.execute("HentRoller", null, "Roller");
  },

  delete(id) {
    const params = { RolleId: id };
    return new Promise((resolve, reject) => {
      api
        .execute("SletteRolle", params, null, true, true)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  /**
   * @name create
   *
   * @param role {Object} Object of type
   * {
   *         "Navn": "",
   *         "Tilgang": {
   *             "Mal": false,
   *             "Konto": false,
   *             "Kontakt": false,
   *             "Send": false,
   *             "Bruker": false
   *         }
   *     }
   * @returns {Promise}
   */
  create(role) {
    const params = {
      Rolle: role
    };
    return api.execute("NyRolle", params);
  },

  /**
   * @name edit
   *
   * @param role {Object} Object of type
   * {
   *         "RolleId": "",
   *         "Navn": "",
   *         "Tilgang": {
   *             "Mal": false,
   *             "Konto": false,
   *             "Kontakt": false,
   *             "Send": false,
   *             "Bruker": false
   *         }
   *     }
   * @returns {Promise}
   */
  edit(role) {
    const params = {
      Rolle: role
    };
    return api.execute("EndreRolle", params);
  }
};

export default apiRole;
