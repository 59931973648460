<template>
  <div>
    <v-autocomplete
      v-model="selected"
      :items="roles"
      item-text="Navn"
      item-value="RolleId"
      :loading="!roles"
      outlined
      :label="$t('roles')"
      @change="emitChange"
      :multiple="true"
      :chips="true"
      :deletable-chips="true"
      data-testid="rolesSelect"
    />
  </div>
</template>

<script>
import apiRole from "@/api/v24/api.role";
import RoleDetails from "@/components/roles/RoleDetails";
import store from "@/store";

export default {
  name: "RoleSelect",
  components: { RoleDetails },
  props: {
    initValue: {
      default: null
    }
  },

  computed: {
    roles() {
      return store.state.roles;
    },
  },

  data: () => ({
    selected: null,
    dialogManage: false
  }),

  created() {
    if (this.initValue) {
      this.selected = this.initValue;
    }
    apiRole.getAll().then(roles => store.commit("setRoles", roles));
  },

  methods: {
    emitChange() {
      this.$emit("change", this.selected);
    }
  }
};
</script>
