<template>
  <v-card>
    <v-card-title>
      {{ $t("systemUsers") }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-spacer />
          <UpdateBtn
            v-on:click="update()"
            class="mr-2 mt-2"
            data-testid="updateSystemUserList"
            :block="$vuetify.breakpoint.xs"
          />
          <ExportBtn
            v-on:click="exportUsers"
            v-bind:class="{ xsBlockFix: $vuetify.breakpoint.xs }"
            class="mt-2 mr-2"
            data-testid="exportSystemUserList"
            :block="$vuetify.breakpoint.xs"
          />
          <v-btn
            @click="newUser"
            class="mt-2"
            data-testid="systemUsersNewUser"
            :block="$vuetify.breakpoint.xs"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t("newUser") }}
          </v-btn>
        </v-row>
        <v-row class="mt-8">
          <v-text-field :label="$t('search')" v-model="search" />
        </v-row>
      </v-container>
      <DataTable
        data-testid="systemUsersNewUserList"
        :items="users"
        :headers="listHeaders"
        :search="search"
        :custom-filter="filterList"
      >
        <template v-slot:item.Administrator="{ item }">
          {{ getIsAdminTblValue(item.Administrator) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-end">
            <v-btn
              outlined
              @click="setUser(item.BrukerId)"
              data-testid="systemUsersDetailsView"
            >
              <v-icon left small>
                mdi-eye
              </v-icon>
              {{ $t("vis") }}
            </v-btn>
          </div>
        </template>
      </DataTable>

      <v-expansion-panels
        data-testid="systemUsersDeactivatedListButton"
        v-model="panel"
        v-if="deactivedUseres.length"
        class="mt-8"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("deactivedUsers") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :items="deactivedUseres"
              :headers="listHeaders"
              data-testid="systemUsersDeactivatedList"
            >
              <template v-slot:item.Administrator="{ item }">
                {{ getIsAdminTblValue(item.Administrator) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="text-end">
                  <v-btn
                    data-testid="systemUsersReactivateUser"
                    outlined
                    @click="reactivateUser(item)"
                  >
                    {{ $t("activate") }}
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-dialog
      v-if="dialogDetails"
      v-model="dialogDetails"
      :max-width="dialogMaxWidth"
      persistent
      scrollable
    >
      <SystemUserDetails
        :user-id="userId"
        v-on:cancel="closeDetailsDialog"
        v-on:save="userSaved"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import apiAdmin from "@/api/v24/api.admin";
import SystemUserDetails from "@/components/userManagement/SystemUserDetails";
import appSnackbar from "@/util/appSnackbac";
import config from "@/config/config";
import UpdateBtn from "@/components/misc/UpdateBtn";
import ExportBtn from "@/components/misc/ExportBtn";
import Export from "@/util/Export";
import user from "@/util/user";
import search from "@/util/search";
import DataTable from "@/components/dataTable/DataTable.vue";

export default {
  name: "SystemUserList",
  components: { DataTable, ExportBtn, UpdateBtn, SystemUserDetails },
  computed: {
    listHeaders() {
      return [
        {
          text: this.$t("name"),
          value: "Navn"
        },
        {
          text: this.$t("email"),
          value: "Epost"
        },
        {
          text: this.$t("mobile"),
          value: "Mobilnr"
        },
        {
          text: this.$t("administrator"),
          value: "Administrator"
        },
        { text: "", value: "actions", sortable: false }
      ];
    }
  },

  data: () => ({
    userId: undefined,
    search: "",
    panel: null,
    users: [],
    deactivedUseres: [],
    dialogDetails: false,
    dialogMaxWidth: "650"
  }),

  created() {
    this.update();
  },

  methods: {
    filterList(value, query) {
      return search.whitespaceAsWildcard(value, query);
    },
    newUser() {
      this.setUser("");
    },
    setUser(userId) {
      this.userId = userId;
      this.dialogDetails = true;
    },
    update() {
      apiAdmin.getUsers(user.getSelectedDepartment()).then(users => {
        this.users = [];
        this.deactivedUseres = [];
        users.forEach(user => {
          if (user.Sperret) {
            this.deactivedUseres.push(user);
          } else {
            this.users.push(user);
          }
        });
      });
    },
    getIsAdminTblValue(adminValue) {
      return adminValue ? this.$t("yes") : this.$t("no");
    },
    reactivateUser(user) {
      apiAdmin.reactivateUser(user).then(() => {
        this.update();
        appSnackbar.setMessage(this.$t("userActivated"));
      });
    },
    closeDetailsDialog() {
      this.dialogDetails = false;
    },
    userSaved() {
      this.closeDetailsDialog();
      this.update();
    },
    exportUsers(options) {
      const headers = [
        this.$t("name"),
        this.$t("email"),
        this.$t("mobile"),
        this.$t("administrator"),
        this.$t("departments"),
        this.$t("roles"),
        this.$t("ID")
      ];
      const stringFromArray = arr => {
        let string = "";
        arr.forEach((obj, idx) => {
          string += obj.Navn;
          if (idx + 1 < arr.length) {
            string += " | ";
          }
        });
        return string;
      };
      const data = this.users.map(user => {
        const mainDepartment = user.Avdelinger.find(dep => dep.Hoved === true);
        return [
          user.Navn,
          user.Epost,
          user.Mobilnr,
          user.Administrator ? this.$t("yes") : this.$t("no"),
          mainDepartment && !user.Administrator ? mainDepartment.Navn : "",
          user.Administrator ? "" : stringFromArray(user.Avdelinger),
          user.Administrator ? "" : stringFromArray(user.Roller),
          user.AnsattId
        ];
      });
      const exporter = new Export(this.$t("systemUsers"), data);
      const exportHeaders = headers;
      exportHeaders.splice(4, 0, this.$t("mainDepartment"));
      exporter.setHeaders(headers);
      options.useExcel ? exporter.exportAsExcel() : exporter.exportAsCsvFile();
    }
  }
};
</script>
<style scoped>
.xsBlockFix {
  width: 100%;
  margin-right: 0 !important;
}
</style>
