<template>
  <SingleMailComposer
    :card-title="$t('sendWelcomeMail')"
    :card-text="$t('sendWelcomeMailInfo')"
    :cancel-btn-txt="$t('dontSend')"
    :init-mail-subject="$t('welcomeToGA')"
    :to-email="toEmail"
    :to-name="toName"
    :sender-name="senderName"
    :init-email-text="mailBody"
    v-on:done="emitDone"
  />
</template>

<script>
import config from "@/config/config";
import SingleMailComposer from "@/components/mail/SingleMailComposer";

export default {
  name: "UserWelcomeMailComposer",
  components: { SingleMailComposer },
  props: {
    toEmail: {
      type: String,
      required: true
    },
    toName: {
      type: String,
      required: true
    },
    senderName: {
      type: String,
      required: true
    }
  },

  data: () => ({
    mailSubject: "",
    mailBody: ""
  }),

  created() {
    this.mailSubject = this.$t("welcomeToGA");
    const onboardingUrl =
      config.onboardingUrl + "?email=" + encodeURIComponent(this.toEmail);
    this.mailBody = this.$t("welcomeMailTemplateText", {
      name: this.senderName,
      url: onboardingUrl
    });
  },

  methods: {
    emitDone() {
      this.$emit("done");
    }
  }
};
</script>
