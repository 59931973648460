<template>
  <v-card>
    <v-card-title>
      {{ cardTitle }}
    </v-card-title>
    <v-card-text>
      <p>
        {{ cardText }}
      </p>
      <v-text-field v-model="emailSubject" outlined :label="$t('subject')" />
      <v-text-field
        :disabled="true"
        v-model="receiverString"
        outlined
        :label="$t('to')"
      />
      <v-textarea v-model="emailText" rows="6" outlined />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn data-testid="userWelcomeMailComposerCancel" @click="cancel">
        {{ cancelBtnTxt }}
      </v-btn>
      <v-btn
        @click="send"
        class="primary"
        data-testid="userWelcomeMailComposerSend"
      >
        {{ $t("sendNow") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import apiEmail from "@/api/v24/api.email";
import appSnackbar from "@/util/appSnackbac";
import i18n from "@/i18n";

export default {
  name: "SingleMailComposer",

  props: {
    cardTitle: {
      type: String,
      required: true
    },
    cardText: {
      type: String,
      default: ""
    },
    cancelBtnTxt: {
      type: String,
      default: i18n.t("cancel")
    },
    initMailSubject: {
      type: String,
      default: ""
    },
    toEmail: {
      type: String,
      required: true
    },
    toName: {
      type: String,
      required: true
    },
    senderName: {
      type: String,
      required: true
    },
    initEmailText: {
      type: String,
      default: ""
    }
  },

  computed: {
    receiverString() {
      return this.toName + " <" + this.toEmail + ">";
    }
  },

  data: () => ({
    emailSubject: "",
    emailText: ""
  }),

  created() {
    this.emailSubject = this.initMailSubject;
    this.emailText = this.initEmailText;
  },

  methods: {
    cancel() {
      this.emitDone();
    },
    send() {
      const recipients = [{ Email: this.toEmail, Name: this.toName }];
      apiEmail
        .send(this.senderName, this.emailSubject, this.emailText, recipients)
        .then(() => {
          appSnackbar.setMessage(this.$t("emailSent"));
          this.emitDone();
        });
    },
    emitDone() {
      this.$emit("done");
    }
  }
};
</script>
