<template>
  <div>
    <v-autocomplete
      v-model="selected"
      @change="change"
      :items="departments"
      item-text="Navn"
      item-value="AvdelingId"
      :item-disabled="isItemReadonly"
      :loading="!departments"
      outlined
      :label="$t('departments')"
      :multiple="true"
      :chips="true"
      :deletable-chips="true"
      return-object
      data-testid="departmentsSelect"
      v-on:focus="emitFocus"
      v-on:blur="emitBlur"
    >
      <template v-slot:prepend-item>
        <v-list-item v-if="readOnlyItemsExists">
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("noDepPermInfo") }}
              <v-icon>mdi-lock</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
      </template>
      <template v-slot:item="{ active, item, attrs, on }">
        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
          <v-list-item-action>
            <v-icon v-if="item._readonly" left>
              mdi-lock
            </v-icon>
            <v-checkbox v-else :input-value="active" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.Navn }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-select
      v-if="selected && selected.length"
      v-model="mainDepartment"
      @change="change"
      :items="selected"
      item-text="Navn"
      item-value="AvdelingId"
      outlined
      class="mt-n4"
      :hint="$t('mainDepartmentInfo')"
      persistent-hint
      dense
      :label="$t('mainDepartment')"
      data-testid="departmentsSelectCheckbox"
    />
  </div>
</template>

<script>
import apiDepartment from "@/api/v24/api.department";
import store from "@/store";
import userProfile from "@/views/UserProfile";

export default {
  name: "DepartmentsMultiSelect",
  props: {
    initValue: {
      default: null
    },
    limitToLoggedInUsersDepartments: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    readOnlyItemsExists() {
      if (this.departments && this.departments.length) {
        return this.departments.some(dep => {
          return dep._readonly;
        });
      }
      return false;
    },
    departments() {
      if (!store.state.departments || !store.state.userProfile) {
        return null;
      }
      if (this.limitToLoggedInUsersDepartments) {
        const userProfile = store.state.userProfile;
        store.state.departments.map(dep => {
          // Main department is in separate field in API structure; must
          // merge it in
          let usersDepartmentIds = [
            userProfile.Bruker.Avdeling,
            ...userProfile.Bruker.Avdelinger.map(dep => dep.AvdelingId)
          ];
          const hit = usersDepartmentIds.some(usrDepId => {
            return usrDepId === dep.AvdelingId;
          });
          if (!hit) {
            dep._readonly = true;
          }
          return dep;
        });

        // Sort array so that the readonly departments are last in the list
        const departments = store.state.departments.sort((a, b) => {
          return a._readonly ? 1 : -1;
        });
        return departments;
      } else {
        return store.state.departments;
      }
    }
  },

  data: () => ({
    selected: null,
    dialogManage: false,
    mainDepartment: null
  }),

  created() {
    store.dispatch("updateDepartments");
    store.dispatch("updateUserProfile");

    if (this.initValue) {
      this.selected = this.initValue;
      this.initValue.forEach(val => {
        if (val.Hoved) {
          this.mainDepartment = val.AvdelingId;
        }
      });
    }
  },

  methods: {
    change() {
      // Make sure main department is not a now removed department
      if (this.selected.length === 1) {
        this.mainDepartment = this.selected[0].AvdelingId;
      } else if (this.selected.length === 0) {
        this.mainDepartment = "";
      } else if (
        !this.selected.some(dep => dep.AvdelingId === this.mainDepartment)
      ) {
        this.mainDepartment = this.selected[0].AvdelingId;
      }
      this.emitChange();
    },
    isItemReadonly(item) {
      return item._readonly;
    },
    emitFocus() {
      this.$emit("focus");
    },
    emitBlur() {
      this.$emit("blur");
    },
    emitChange() {
      this.$emit("change", {
        selectedDepartments: this.selected.map(selected => selected.AvdelingId),
        mainDepartment: this.mainDepartment
      });
    }
  }
};
</script>
